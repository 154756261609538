import React, { useState } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ReferenceField,
  ImageField,
  ImageInput,
} from "react-admin";
import { ColorField } from "react-admin-color-input";
import { VideoField } from "../../components/VideoField";
import { TopToolbar, EditButton } from "react-admin";
import { DeployButton } from "../../components/DeployButton";

const ConfigActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && <DeployButton data={data} />}
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const ConfigShow = (props) => (
  <Show actions={<ConfigActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      {/* <TextField source="createdate" label="Created at" />
        <TextField source="lastupdate" label="Updated at" /> */}
      <TextField source="title" />
      <ReferenceField label="Client" source="client_id" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <RichTextField source="project_type" />
      <TextField source="socials_twitter" label="Twitter URL" />
      <TextField source="socials_twitter_handle" label="Twitter Handle" />
      <TextField source="socials_telegram" label="Telegram URL" />
      <TextField source="socials_blog" label="Blog URL" />
      <TextField source="socials_facebook" label="Facebook URL" />
      <TextField source="socials_instagram" label="Instagram URL" />
      <TextField source="socials_linkedin" label="Linkedin URL" />
      <TextField source="meta_title" label="Title" />
      <TextField source="meta_description" label="Description" />
      <TextField source="meta_keywords" label="Keywords" />
      <TextField source="meta_url" label="URL" />
      <TextField source="meta_twitter_title" label="Twitter title" />
      <ImageField
        source="meta_twitter_image.src"
        title="title"
        label="Meta Twitter Image"
      />
      <ImageField
        source="meta_card_image.src"
        title="title"
        label="Meta Card Image"
      />
      <ImageField source="logo.src" title="title" label="Navbar logo" />
      <ImageField source="footer_logo.src" title="title" label="Footer logo" />
      <ImageField
        source="splash_image.src"
        title="title"
        label="Splash Image"
      />
      <ImageField source="favicon.src" title="title" label="Favicon" />
      <TextField source="support_email" label="Support email" />
      <TextField source="super_user_name" />
      <TextField source="base_url" />
      <ColorField source="color_primary" />
      <ColorField source="color_secondary" />
      <ColorField source="font_color" />
      <ColorField source="qr_code_color" />
      <ColorField source="form_color" />
      <VideoField
        source="video_file"
        path="src"
        title="title"
        label="Video File"
      />
    </SimpleShowLayout>
  </Show>
);
