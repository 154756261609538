import * as React from "react";
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";

const ClientFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const ClientList = (props) => (
  <List {...props} filters={<ClientFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="website" />
      <TextField source="details" />
      <ShowButton label="View" />
      <EditButton label="Edit" />
      <DeleteButton label="Delete" redirect={false} />
    </Datagrid>
  </List>
);

export const ClientShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="website" />
      <TextField source="details" fullWidth />
    </SimpleShowLayout>
  </Show>
);

export const ClientCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="website" />
      <TextInput source="details" fullWidth />
    </SimpleForm>
  </Create>
);

export const ClientEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="website" />
      <TextInput source="details" fullWidth />
    </SimpleForm>
  </Edit>
);
