import * as React from "react";

import { ConfigCreate } from "./resources/config/Create";
import { ConfigEdit } from "./resources/config/Edit";
import { ConfigShow } from "./resources/config/Show";
import { ConfigList } from "./resources/config/List";
import {
  ClientList,
  ClientShow,
  ClientCreate,
  ClientEdit,
} from "./resources/clients";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import { createTheme } from "@material-ui/core/styles";

import firebase from "firebase/compat/app";

import ClientIcon from "@material-ui/icons/People";

import CustomLoginPage from "./CustomLoginPage";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const firebaseApp = firebase.initializeApp(firebaseConfig);

console.log({ firebaseConfig, firebaseApp });

const authProvider = FirebaseAuthProvider(firebaseConfig);
const dataProvider = FirebaseDataProvider(firebaseConfig, {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ['configs'];
  // dontwatch: ['comments'];
  persistence: "local",
  // disableMeta: true
  dontAddIdFieldToDoc: true,
  lazyLoading: {
    enabled: true,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
});

const theme = createTheme({
  overrides: {
    RaFormInput: {
      input: {
        minWidth: "500px",
      },
    },
    RaSelectInput: {
      input: {
        minWidth: "500px",
      },
    },
    MuiTextField: {
      root: {
        minWidth: "500px",
      },
    },
    RaFileInputPreview: {
      removeButton: {
        zIndex: 3,
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "#3f51b5"
      }
    }
  },
});

class App extends React.Component {
  render() {
    return (
      <Admin
        theme={theme}
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="configs"
          list={ConfigList}
          show={ConfigShow}
          create={ConfigCreate}
          edit={ConfigEdit}
        />
        <Resource
          name="clients"
          icon={ClientIcon}
          list={ClientList}
          show={ClientShow}
          create={ClientCreate}
          edit={ClientEdit}
        />
      </Admin>
    );
  }
}

export default App;
