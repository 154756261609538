import React, { useState, useEffect } from "react";
import { Button, useMutation, useNotify, useAuthProvider } from "react-admin";
import { Link } from "react-router-dom";
import Send from "@material-ui/icons/Send";
import Info from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const DEPLOY_INTERVAL = 5 * 60 * 1000; // 5 min
const DEPLOY_FUNCTION_URL =
  "https://us-central1-token-ocean-admin.cloudfunctions.net/doDeploy"; // Cloud function url

const useStyles = makeStyles({
  button: {
    fontWeight: "bold",
    // '& svg': { color: 'orange' }
    color: "green",
    marginRight: "3px",
  },
  svg: {
    paddingTop: "5px",
  },
});

export const DeployButton = ({ data }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [deployInProgress, setDeployInprogress] = useState(true);
  const [infoLabel, setInfoLabel] = useState("Deploy info");
  const authProvider = useAuthProvider();

  useEffect(() => {
    if (data) {
      initState(data);
    }
  }, [data]);

  const initState = (data) => {
    // debugger;
    const deployReadyAt = data.deployed_at
      ? new Date(data.deployed_at).getTime() + DEPLOY_INTERVAL
      : null;

    if (deployReadyAt && deployReadyAt > new Date().getTime()) {
      setDeployInprogress(true);
      setInfoLabel(
        `Deploy in progress (Ready at ~${new Date(
          deployReadyAt
        ).toTimeString()})`
      );
      return;
    }

    setDeployInprogress(false);
  };

  const [updateConfig, { loading }] = useMutation({
    type: "update",
    resource: "configs",
    payload: {
      id: data && data.id,
      data: data ? { ...data, ...{ deployed_at: new Date() } } : {},
    },
  });

  const doDeploy = async () => {
    await updateConfig();

    const jwtToken = await authProvider.getJWTToken();

    try {
      const deployResponse = await fetch(DEPLOY_FUNCTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          configId: data.id,
        }),
      });

      console.log("deployResponse", JSON.stringify(deployResponse));
    } catch (error) {
      console.log("error", JSON.stringify(error));
    }
  };

  return (
    <>
      {deployInProgress && (
        <Tooltip title={infoLabel}>
          <Info className={classes.svg} fontSize="small" />
        </Tooltip>
      )}
      <Button
        className={classes.button}
        label={deployInProgress ? "Deploying.." : "Deploy"}
        color="primary"
        key="button"
        disabled={deployInProgress}
        onClick={doDeploy}
      >
        <Send />
      </Button>
    </>
  );
};
