import React, { useRef, useState, useEffect } from "react";
import { Labeled } from "react-admin";

export const VideoField = ({ source, record, title, onSize, path, label }) => {
  const videoSource = useRef(null);
  const [src, setSrc] = useState("");
  const getRecordFromSource = () => path && record[source] ? record[source][path]  : record[source || ""]
  const realRecord = typeof record === "string" ? record : getRecordFromSource();
  // realRecord = path ?
  // debugger

  useEffect(() => {
    setSrc(realRecord);
  }, [src]);

  return (
    <>
    {label ? <Labeled label={label} fullWidth></Labeled> : <></>}
      {src ? (
    <video
      controls
      title={title}
      src={src}
      style={{
        maxWidth: "500px"
      }}
      onLoadedMetadata={(e) => {
        onSize && onSize(e.target);
      }}
      ref={videoSource}
    >
      Your browser does not support the video element.
    </video>
  ) : (<></>)}
    </>
  );
};
