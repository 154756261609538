import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  ReferenceField,
} from "react-admin";

const doDeploy= (props, record) => {
  debugger
}

export const ConfigList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <RichTextField source="project_type" />
      <ReferenceField label="Client" source="client_id" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton label="View" />
      <EditButton label="Edit" />
      <DeleteButton label="Delete" redirect={false} />
    </Datagrid>
  </List>
);
