import React, { useState } from "react";
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  ImageField,
  ImageInput,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  Labeled,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { makeStyles } from "@material-ui/core";
import { PROJECT_TYPES, LNFT, IPDP } from "../../const";
import { validate } from "../../helpers";
import { VideoField } from "../../components/VideoField";

export const ConfigCreate = (props) => {
  const [videoWidth, setVideoWidth] = useState(null);
  const [videoHeight, setVideoHeight] = useState(null);

  const validateCreate = (values) => {
    const errors = {};

    if (
      (videoWidth && videoWidth !== 1920) ||
      (videoHeight && videoHeight !== 1080)
    ) {
      errors.video_file = "Video resolution should be 1920x1080.";
    }

    return errors;
  };

  const useDropzoneStyles = makeStyles(
    (theme) => ({
      dropZone: {
        display: "none",
      },
    }),
    { name: "RaFileInput" }
  );
  const classes = useDropzoneStyles(props);

  const isDropzoneVisible = (key, data) => {
    return !!data[key];
  };

  const renderCommonInputs = (formData) => (
    <>
      <TextInput source="title" validate={validate("Title")} />
      <div></div>
      <ReferenceInput
        label="Client"
        source="client_id"
        reference="clients"
        validate={validate("Client")}
      >
        <SelectInput label="Client" optionText="name" />
      </ReferenceInput>
      <Labeled label="Socials" fullWidth>
        <>
          <TextInput source="socials_twitter" label="Twitter URL" />
          <div></div>
          <TextInput
            source="socials_twitter_handle"
            label="Twitter Handle"
            helperText="Ex: @example"
          />
          <div></div>
          <TextInput source="socials_telegram" label="Telegram URL" />
          <div></div>
          <TextInput source="socials_blog" label="Blog URL" />
          <div></div>
          <TextInput source="socials_facebook" label="Facebook URL" />
          <div></div>
          <TextInput source="socials_instagram" label="Instagram URL" />
          <div></div>
          <TextInput source="socials_linkedin" label="Linkedin URL" />
        </>
      </Labeled>
      <Labeled label="Meta tags" fullWidth>
        <>
          <TextInput source="meta_title" label="Title" />
          <div></div>
          <TextInput source="meta_description" label="Description" />
          <div></div>
          <TextInput source="meta_keywords" label="Keywords" />
          <div></div>
          <TextInput source="meta_url" label="URL" />
          <div></div>
          <TextInput source="meta_twitter_title" label="Twitter title" />
          <div></div>
          <ImageInput
            source="meta_twitter_image"
            label="Twitter Image"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <div></div>
          <ImageInput
            source="meta_card_image"
            label="Card Image"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </>
      </Labeled>
      <ImageInput
        source="logo"
        label="Navbar logo"
        accept="image/png"
        validate={validate("Logo")}
        classes={isDropzoneVisible("logo", formData) ? classes : {}}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="footer_logo"
        label="Footer logo"
        accept="image/png"
        validate={validate("Footer logo")}
        classes={isDropzoneVisible("footer_logo", formData) ? classes : {}}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="splash_image"
        label="Splash Image"
        accept="image/jpeg"
        validate={validate("Splash Image")}
        classes={isDropzoneVisible("splash_image", formData) ? classes : ""}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="favicon"
        label="Favicon"
        accept="image/vnd.microsoft.icon,x-icon"
        validate={validate("Favicon")}
        classes={isDropzoneVisible("favicon", formData) ? classes : ""}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="support_email" label="Support email" />
      <div></div>
      <SelectInput
        validate={validate("Project Type")}
        label="Project Type"
        source="project_type"
        choices={PROJECT_TYPES}
      />
    </>
  );
  const renderByType = (type, validationCallback, formData) => {
    switch (type) {
      case LNFT:
        return (
          <>
            <div></div>
            <TextInput source="super_user_name" />
            <div></div>
            <TextInput source="base_url" helperText="Ex: example.com" />
            <ColorInput source="color_primary" />
            <ColorInput source="color_secondary" />
          </>
        );

      case IPDP:
        return (
          <>
            <ImageInput
              source="video_file"
              label="Video"
              accept="video/*"
              validate={validate("Video")}
              classes={isDropzoneVisible("video_file", formData) ? classes : ""}
            >
              <VideoField
                source="src"
                title="title"
                onSize={(eventTarget) => {
                  validationCallback(eventTarget);
                }}
              />
            </ImageInput>
            <Labeled label="Color scheme" fullWidth>
              <>
                <ColorInput source="font_color" />
                <div></div>
                <ColorInput source="qr_code_color" />
                <div></div>
                <ColorInput source="form_color" />
              </>
            </Labeled>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <Create {...props}>
      <SimpleForm validate={validateCreate}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                {renderCommonInputs(formData)}
                {renderByType(formData.project_type, (eventTarget) => {
                  eventTarget &&
                    eventTarget.videoHeight &&
                    setVideoHeight(eventTarget.videoHeight);
                  eventTarget &&
                    eventTarget.videoWidth &&
                    setVideoWidth(eventTarget.videoWidth);
                }, formData)}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
